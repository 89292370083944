import styled from 'styled-components'

import { View, ViewHeader, ViewTitle } from '../../components'
import { Card, PremiumBenefits } from '../../../components'
import { Button, FlexBox, ListItem, UnsortedList } from 'components/generic'
import { PlusIcon } from 'components/icons'

import { useChartData } from 'tree/providers'
import { useChartPermission } from 'tree/hooks'
import { getIsSubscribed, UpgradeSubscriptionButton } from 'features/premium'
import { colors, spaces } from 'theme'

import insightsEmptySrc from 'assets/custom-fields-empty.png'

type Props = {
  viewTitle: string
  onClick: () => void
}

export const Empty = ({ viewTitle, onClick }: Props) => {
  const { subscription } = useChartData()
  const { canCreateCustomField } = useChartPermission()
  const isSubscribed = getIsSubscribed(subscription)

  return (
    <View>
      <ViewHeader>
        <ViewTitle>{viewTitle}</ViewTitle>
      </ViewHeader>

      <StyledCard
        title={
          canCreateCustomField
            ? 'Create well-arranged organization charts with custom fields.'
            : 'Your chart has no custom fields yet.'
        }
      >
        <TextHint>(Custom fields are not visible on sharable links)</TextHint>

        <Content>
          <FlexBox $isDirectionColumn $spacing={{ mb: spaces.m }}>
            <Text>You can use them for many use cases:</Text>
          </FlexBox>

          <UnsortedList $gap='m'>
            <ListItem iconColor={colors.purpleLighter}>Note projects your employees are working on</ListItem>
            <ListItem iconColor={colors.purpleLighter}>Track of specific skills</ListItem>
            <ListItem iconColor={colors.purpleLighter}>Add favorite food and drink</ListItem>
            <ListItem iconColor={colors.purpleLighter}>Lots of other uses</ListItem>
          </UnsortedList>
        </Content>

        <FlexBox>
          {isSubscribed && canCreateCustomField && (
            <Button onClick={onClick} data-testid='button-create-a-new-custom-field'>
              <PlusIcon $spacing={{ mr: spaces.m }} />
              Create custom field
            </Button>
          )}
          {!isSubscribed && <UpgradeSubscriptionButton />}
        </FlexBox>

        {!isSubscribed && <PremiumBenefits spacing={{ mt: spaces.xl }} />}

        <CustomFieldsEmptyImg src={insightsEmptySrc} alt='custom fields are empty' />
      </StyledCard>
    </View>
  )
}

const StyledCard = styled(Card)`
  overflow: hidden;
`

const CustomFieldsEmptyImg = styled.img`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    position: absolute;
    top: 40px;
    right: -125px;
    width: 100%;
    max-width: 460px;
    display: block;
  }
`

const Content = styled.div`
  margin-bottom: ${props => props.theme.spaces.l};
`

const Text = styled.div`
  color: ${props => props.theme.colors.dark};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

const TextHint = styled(Text)`
  font-size: 13px;
  margin-top: -${props => props.theme.spaces.l};
  margin-bottom: ${props => props.theme.spaces.l};
  color: ${props => props.theme.colors.greyDark};
`
