import { ReactNode } from 'react'

import { GenericOnErrorModalType } from 'components/specific'
import { Button } from 'components/generic'

import { useGoogleWorkspace } from './use-google-workspace'
import { useModal } from 'modal'
import analytics from 'analytics'
import { spaces } from 'theme'
import { TrackKeyName } from 'types'

type Params = {
  description: ReactNode
  redirectPath: string
  title: string
  trackName: TrackKeyName
}

export const useRequestCalendar = () => {
  const { authorize } = useGoogleWorkspace()
  const { open } = useModal()

  const requestCalendar = ({ description, redirectPath, title, trackName }: Params) => {
    open<GenericOnErrorModalType>('genericOnErrorModal', {
      title,
      children: description,
      button: (
        <Button
          spacing={{ mt: spaces.l, ml: 'auto' }}
          onClick={() => {
            analytics.track(trackName, { syncCalendar: true })
            authorize({
              redirectPath,
              scopes: ['calendarReadonly'],
              includeGrantedScopes: true,
            })
          }}
        >
          Grant Google Calendar permission
        </Button>
      ),
    })
  }

  return { requestCalendar }
}
