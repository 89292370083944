import { useEffect } from 'react'

import { Screen } from './components'
import { MaintenanceIcon } from 'components/icons'

import { useAuth } from 'auth'
import analytics from 'analytics'
import { ERRORS_TRACK } from 'analytics/constants'
import { ORG_CHART_SUPPORT_EMAIL } from 'consts'

export const ContactUs = () => {
  const { user, isAuthenticated, logout } = useAuth()
  const { email, name, isEmailVerified } = user

  const { authProvider } = user

  const subDescription =
    authProvider === 'auth0'
      ? 'You are not able to log in because you registered using your Google account. Please try again and select Log in with Google.'
      : 'You are not able to log in because you did not register using your Google account. Please try again and select Log in, you will be need to provide your email and password.'

  useEffect(() => {
    analytics.track(ERRORS_TRACK.authProviderIssue, { authProvider, email, name, isEmailVerified })
  }, [])

  return (
    <Screen
      icon={<MaintenanceIcon />}
      title='Something went wrong'
      description={`Please contact us at ${ORG_CHART_SUPPORT_EMAIL}`}
      subDescription={`${subDescription} If you are still having trouble, please contact us at ${ORG_CHART_SUPPORT_EMAIL}.`}
      descMaxWidth='500px'
      button={isAuthenticated ? { buttonType: 'secondary', children: 'Log out', onClick: () => logout() } : undefined}
    />
  )
}
