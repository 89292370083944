import styled from 'styled-components'

import { Link } from 'components/generic'

import { ORG_CHART_UNARIC_DPA_URL } from 'consts'

import OrgchartDesktopTrailer from 'assets/orgchart-desktop-trailer.png'
import OrgchartMobileTrailer from 'assets/orgchart-mobile-trailer.png'

export const OrgShowcase = () => (
  <Container>
    <ShowcaseCentering>
      <ShowcaseMobileImage src={OrgchartMobileTrailer} />
      <ShowcaseDesktopImage src={OrgchartDesktopTrailer} />
      <InfoWrapList>
        <InfoWrapItem>
          <Heading>Create Org Charts in minutes</Heading>
          <ShowcaseText>
            Instantly create your first Org Chart by importing your Google Workspace directory. Save hours of valuable
            time by getting started with a few clicks of a button!
          </ShowcaseText>
        </InfoWrapItem>
        <InfoWrapItem>
          <Heading>Beautifully Design Exports</Heading>
          <ShowcaseText>Export in PDF or PNG to share your Org Charts quickly and easily.</ShowcaseText>
        </InfoWrapItem>
        <InfoWrapItem>
          <Heading>Collaborate with your colleagues</Heading>
          <ShowcaseText>
            Share your Org Chart with coworkers. This improves visibility and improves workforce management planning.
          </ShowcaseText>
        </InfoWrapItem>
        <InfoWrapItem>
          <Heading>Customization made easy</Heading>
          <ShowcaseText>
            With customized labels, create Org Charts to reflect how your team operates. Label team members by
            seniority, team, or even utilize things like vacation policy for more insights.
          </ShowcaseText>
        </InfoWrapItem>
        <InfoWrapItem>
          <Heading>Drag and Drop</Heading>
          <ShowcaseText>
            Create your Org Charts manually from scratch by dragging and dropping employees and teams.
          </ShowcaseText>
        </InfoWrapItem>
        <InfoWrapItem>
          <Heading>Fully GDPR compliant</Heading>
          <ShowcaseText>
            Data privacy is a cornerstone value of Time is Ltd. We&apos;ll never share or sell your data with a 3rd
            party vendor.
            <br />
            <br />
            To find out more about our privacy policy, please see our{' '}
            <Link href={ORG_CHART_UNARIC_DPA_URL} target='blank'>
              data processing agreement.
            </Link>
          </ShowcaseText>
        </InfoWrapItem>
      </InfoWrapList>
    </ShowcaseCentering>
  </Container>
)

const Heading = styled.div`
  margin-bottom: 8px;
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`

const Text = styled.div`
  width: 100%;
  max-width: 338px;
  color: ${props => props.theme.colors.greyMedium};
  font-size: 13px;
  line-height: 18px;
`

const ShowcaseText = styled(Text)`
  margin-bottom: 0;
`

const Container = styled.div`
  flex: 1 0 auto;
  box-sizing: border-box;
  padding: 20px 0 50px;
  background: ${props => props.theme.colors.neutralSecondary};
  text-align: center;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 60px;
    padding-right: 30px;
    margin-left: calc(100vw / 2.5);
  }
`

const ShowcaseCentering = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ShowcaseImage = styled.img`
  width: 100%;
  max-width: 600px;
  margin-right: auto;
  margin-bottom: 35px;
  margin-left: auto;
`

const ShowcaseMobileImage = styled(ShowcaseImage)`
  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    display: none;
  }
`

const ShowcaseDesktopImage = styled(ShowcaseImage)`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    display: block;
    max-width: 860px;
    margin-bottom: 90px;
  }
`

const InfoWrapList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 40px 40px;
  max-width: 670px;
  padding: 0 20px;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    padding: 0 40px;
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    max-width: 680px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 32px 80px;
    margin: 0;
    padding: 0;
  }
  @media (min-width: ${props => props.theme.deviceBreakpoints.smallDesktop}) {
    max-width: 720px;
  }
`

const InfoWrapItem = styled.div`
  width: 100%;
  max-width: 335px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    max-width: 300px;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 0;
  }
`
