import styled from 'styled-components'

import { Link } from 'components/generic'

import { getIsCustomInitialized, showNewMessage } from 'analytics/intercom'
import { capitalize } from 'helpers'
import { ORG_CHART_SUPPORT_EMAIL } from 'consts'

type Props = {
  intercomMsg?: string
  capFirstLetter?: boolean
}

export const ContactLink = ({ intercomMsg = '', capFirstLetter }: Props) => {
  const intercomText = ' Contact us via Intercom'
  const mailText = ' Email us at '

  return getIsCustomInitialized() ? (
    <Link as='div' onClick={() => showNewMessage(intercomMsg)}>
      {capFirstLetter ? capitalize(intercomText) : intercomText}
    </Link>
  ) : (
    <ContactTroughEmail>
      {capFirstLetter ? capitalize(mailText) : mailText}
      <Link href={`mailto:${ORG_CHART_SUPPORT_EMAIL}`}>{ORG_CHART_SUPPORT_EMAIL}</Link>
    </ContactTroughEmail>
  )
}

const ContactTroughEmail = styled.div`
  display: inline-block;
`
