import { Formik } from 'formik'

import { NodeFormButtons } from '../../../node-components'
import { SuperiorFieldCreate, SuperiorField, SuperiorErrorMessage } from 'components/specific/superior-field'
import { SelectColorPalette } from 'components/specific/select-color-palette'
import { UnsavedPrompt } from 'components/specific/unsaved-prompt'
import { FieldInput, FieldContainer, FieldTitle } from 'components/generic'

import {
  DepartmentFormCapabilities,
  DepartmentFormValues,
  DepartmentFormErrors,
  OnDepartmentFormSubmit,
  DepartmentFormSubmitValues,
} from 'tree/types'
import { useStateCallback } from 'hooks'

import { DepartmentDetailDataFragmentFragment } from 'apollo/generated/graphql'

type Props = {
  capabilities: DepartmentFormCapabilities
  chartUuid: string
  initialValues: DepartmentFormValues
  onClose: () => void
  onSubmit: OnDepartmentFormSubmit
  department?: DepartmentDetailDataFragmentFragment
  onCancel?: () => void
}

export const DepartmentForm = (props: Props) => {
  const { capabilities, chartUuid, initialValues, onClose, onSubmit, department, onCancel, ...formikProps } = props
  const { canUpdate, canMove } = capabilities

  const [revokeUnsavedPrompt, setRevokeUnsavedPrompt] = useStateCallback(false)

  return (
    <Formik
      {...formikProps}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        const { moveToChart, ...reducedValues } = values
        const stayInDirectory = department?.unassigned && !moveToChart
        const newValues: DepartmentFormSubmitValues = {
          ...reducedValues,
          parentUuid: stayInDirectory ? undefined : reducedValues.parentUuid,
        }
        onSubmit({ values: newValues, actions })
      }}
      validate={values => {
        const { name, parentUuid } = values
        const errors: DepartmentFormErrors = {}
        if (!name) errors.name = 'Required.'
        if (parentUuid === '') errors.parentUuid = 'Either enter valid employee/department or leave empty.'

        // Length validation
        const MAX_LENGTH = 255
        const lengthErrorMessage = (maxChars: number) => `Field can't be longer than ${maxChars} characters.`
        Object.keys(values).forEach(_keyName => {
          const keyName = _keyName as keyof typeof values
          const value = values[keyName]
          if (typeof value === 'string') {
            if (value.length > MAX_LENGTH) (errors as any)[keyName] = lengthErrorMessage(MAX_LENGTH)
          }
        })

        if (Object.keys(errors).length > 0) setRevokeUnsavedPrompt(false)
        return errors
      }}
    >
      {formState => {
        const { values, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } = formState
        const { moveToChart } = values

        return (
          <form
            onSubmit={e => {
              e.preventDefault()
              setRevokeUnsavedPrompt(true, () => handleSubmit(e))
            }}
          >
            <UnsavedPrompt when={!revokeUnsavedPrompt && dirty} />

            {canMove && (
              <FieldContainer>
                <FieldTitle title='Supervisor and department' />
                {department ? (
                  <SuperiorField
                    chartUuid={chartUuid}
                    moveToChart={moveToChart}
                    node={department}
                    parentUuid={values.parentUuid}
                    handleSetFieldValue={setFieldValue}
                  />
                ) : (
                  <SuperiorFieldCreate
                    chartUuid={chartUuid}
                    parentUuid={values.parentUuid}
                    handleSetFieldValue={setFieldValue}
                  />
                )}
                <SuperiorErrorMessage />
              </FieldContainer>
            )}

            <FieldInput
              title={{ title: 'Name', required: true }}
              name='name'
              type='text'
              placeholder='Enter department name'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!canUpdate}
              value={values.name || ''}
              onClear={() => setFieldValue('name', '')}
            />

            <FieldContainer>
              <FieldInput
                title={{ title: 'Description' }}
                name='description'
                type='text'
                placeholder='Enter description'
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!canUpdate}
                value={values.description || ''}
                onClear={() => setFieldValue('description', '')}
              />
            </FieldContainer>

            {canUpdate && (
              <FieldContainer>
                <FieldTitle title='Color' />
                <SelectColorPalette value={values.color} onChange={handleChange} />
              </FieldContainer>
            )}

            <NodeFormButtons
              isSubmitDisabled={isSubmitting || !dirty}
              onCancel={() => setRevokeUnsavedPrompt(true, onCancel ?? onClose)}
              onSubmit={() => setRevokeUnsavedPrompt(true, () => handleSubmit())}
            />
          </form>
        )
      }}
    </Formik>
  )
}
