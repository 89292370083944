import { CalendarLink } from './calendar-link'
import { CalendarPermissionRequest } from './calendar-permission-request'
import { CustomFieldsDetail } from './custom-fields-detail'
import { GroupHeading } from '../../components'
import { NodeDetailItem } from '../../../node-components'
import { ProfileInfoCardIcon } from 'components/icons'

import { useChartData, useChartSidePanelDetail } from 'tree/providers'
import { useGoogleWorkspace } from 'tree/hooks'
import { AboutFieldsOnDetail, CustomFieldValueFilledWithoutConnection, PersonDetail } from 'tree/types'

type Props = {
  person: PersonDetail
}

export const AboutFieldsDetail = ({ person }: Props) => {
  const { calendarUrl, employeeId, employeeType, customFieldsFilled } = person

  const { hasScope } = useGoogleWorkspace()
  const { providerInfo, userPermission } = useChartData()
  const isGsuiteProvider = providerInfo?.name === 'gsuite'
  const hasCalendarScope = hasScope('calendarReadonly')
  const showGoogleCalendarPermissionRequest = isGsuiteProvider && !hasCalendarScope && userPermission?.role === 'admin'

  const customFieldsFilledWithoutConnection = customFieldsFilled.filter(
    cf => cf.type !== 'connection'
  ) as CustomFieldValueFilledWithoutConnection[]

  const aboutFields: AboutFieldsOnDetail = {
    calendarUrl,
    employeeId,
    employeeType,
    customFieldsFilled: customFieldsFilledWithoutConnection,
  }
  const showAboutFields =
    calendarUrl ||
    employeeId ||
    employeeType ||
    showGoogleCalendarPermissionRequest ||
    customFieldsFilledWithoutConnection.length > 0

  if (!showAboutFields) {
    return null
  }

  return (
    <AboutFieldsDetailInner
      aboutFields={aboutFields}
      showGoogleCalendarPermissionRequest={showGoogleCalendarPermissionRequest}
    />
  )
}

type PropsInner = {
  aboutFields: AboutFieldsOnDetail
  showGoogleCalendarPermissionRequest: boolean
}

export const AboutFieldsDetailInner = ({ aboutFields, showGoogleCalendarPermissionRequest }: PropsInner) => {
  const { calendarUrl, employeeId, employeeType, customFieldsFilled } = aboutFields
  const { isAboutExpanded, setIsAboutExpanded } = useChartSidePanelDetail()

  return (
    <section>
      <GroupHeading
        icon={<ProfileInfoCardIcon width='17px' />}
        title='About'
        isExpanded={isAboutExpanded}
        onClick={() => setIsAboutExpanded(!isAboutExpanded)}
      />

      {isAboutExpanded && (
        <>
          {calendarUrl && <CalendarLink url={calendarUrl} />}
          {showGoogleCalendarPermissionRequest && <CalendarPermissionRequest />}
          {employeeType && <NodeDetailItem title='Employee type' value={employeeType} />}
          {employeeId && <NodeDetailItem title='Employee Id' value={employeeId} />}
          <CustomFieldsDetail customFields={customFieldsFilled} />
        </>
      )}
    </section>
  )
}
