import globalConfig from 'config'

export const TIL_URL = 'https://www.timeisltd.com'
export const TIL_CONTACT_URL = 'https://www.timeisltd.com/contact'
export const TIL_HR_URL = 'https://www.timeisltd.com/hr'
export const ORG_CHART_UNARIC_DPA_URL = 'https://orgchart.unaric.com/data-processing-agreement'
export const ORG_CHART_UNARIC_TERMS_CONDITIONS_URL = 'https://orgchart.unaric.com/terms-conditions'

export const ORG_CHART_TIL_URL = 'https://orgchart.timeisltd.com'
export const ORG_CHART_HELP_CENTER_URL = globalConfig.HELP_CENTER_URL
export const CALENDAR_ANALYTICS_TIL_URL = 'https://calendaranalytics.timeisltd.com'

export const ANALYTICS_TIL_LOGIN_URL = 'https://analytics.timeisltd.com/login'
export const ANALYTICS_TIL_LOGIN_GOOGLE_URL = 'https://analytics.timeisltd.com/login-google'
export const ANALYTICS_TIL_LOGIN_TEAMS_URL = 'https://analytics.timeisltd.com/login-teams'
export const ANALYTICS_TIL_LOGIN_ZOOM_URL = 'https://analytics.timeisltd.com/login-zoom'

export const WORKSPACE_GOOGLE_MARKETPLACE_ORG_CHART_URL =
  'https://workspace.google.com/marketplace/app/org_chart_best_chart_builder/219218309058'

export const WHATSAPP_CLICK_TO_CHAT_LINK = 'https://wa.me'
