import { generatePath, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { NodeDetailItem } from '../../../node-components'
import { LinkStyles } from 'components/generic'
import { LinkIcon } from 'components/icons'

import { useRequestCalendar } from 'tree/hooks'
import { EMPLOYEE_DETAIL_CONNECT_GW_PATH } from 'routes'
import { PERMISSIONS_TRACK } from 'analytics/constants'

export const CalendarPermissionRequest = () => {
  const { params } = useRouteMatch<Record<string, string>>()

  const { requestCalendar } = useRequestCalendar()
  const handleRequestCalendar = () => {
    requestCalendar({
      title: 'Show Google Calendar in employee detail',
      description: "Easily access any employee's Google calendar directly from their employee card in the Org Chart.",
      trackName: PERMISSIONS_TRACK.addGoogleCalPerEmplDet,
      redirectPath: generatePath(EMPLOYEE_DETAIL_CONNECT_GW_PATH, params),
    })
  }

  return (
    <NodeDetailItem
      title='Calendar'
      value={
        <StyledLink onClick={handleRequestCalendar}>
          Open employee calendar
          <StyledLinkIcon />
        </StyledLink>
      }
    />
  )
}

const StyledLink = styled.div`
  ${LinkStyles}
  align-items: center;
`

const StyledLinkIcon = styled(LinkIcon)`
  margin-left: ${props => props.theme.spaces.m};
  margin-bottom: 2px;
`
